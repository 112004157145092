@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #007bff; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Scrollbar track color */
}
